<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import CodeEditor from "simple-code-editor";
import html2canvas from "html2canvas";

import SpinnerIcon from "../icons/SpinnerIcon.vue";
import TrashIcon from "../icons/TrashIcon.vue";
import CopyIcon from "../icons/CopyIcon.vue";
import router from "@/router";

const route = useRoute();
const store = useStore();

const form = ref({
  title: "",
  content: "",
  snippet_id: "",
  tags: [],
  assets: [],
  keptAssets: [],
  thumbnail: null,
});

const variantId = route.params.variantId;
const isEditing = ref(variantId ? true : false);

const isSubmitting = ref(false);
const submissionError = ref("");

const assets = ref([]);

const variant = computed(() => store.state.currentVariant);
const tags = computed(() => store.state.tags);
const snippet = computed(() => store.state.currentSnippet);

const isDisabled = computed(() => {
  const title = form.value.title.trim();
  const content = form.value.content.trim();
  return title === "" || content === "";
});

const toggleTag = (tag) => {
  const tagIndex = form.value.tags.findIndex((t) => t == tag.id);
  if (tagIndex == -1) {
    form.value.tags.push(tag.id);
  } else {
    form.value.tags.splice(tagIndex, 1);
  }
};

const handleFiles = (e) => {
  const files = e.target.files;

  for (const file of files) {
    const url = URL.createObjectURL(file);
    assets.value.push({
      id: assets.value.length,
      url,
      name: file.name,
      file,
    });
  }

  form.value.assets = assets.value;
};

const removeAsset = (asset) => {
  const assetIndex = assets.value.indexOf(asset);
  assets.value.splice(assetIndex, 1);
};

const copyAssetUrl = (url) => {
  navigator.clipboard.writeText(url);
};

const removeKeptAsset = (asset) => {
  const assetIndex = form.value.keptAssets.indexOf(asset);
  form.value.keptAssets.splice(assetIndex, 1);
};

const takeScreenshot = async () => {
  const tableElement = document.querySelector("table");
  const canvas = await html2canvas(tableElement);
  return canvas.toDataURL("image/png");
};

const handleSubmit = async () => {
  if (isSubmitting.value) {
    return;
  }

  isSubmitting.value = true;
  submissionError.value = "";

  const screenshotDataUrl = await takeScreenshot();

  let formData = new FormData();
  formData.append("title", form.value.title);
  formData.append("content", form.value.content);
  formData.append("snippet_id", snippet.value.id);
  form.value.tags.forEach((tag) => formData.append("tags[]", tag));
  form.value.assets.forEach((asset, index) => {
    if (asset.file) {
      formData.append(`assets[${index}][file]`, asset.file, asset.file.name);
      formData.append(`assets[${index}][url]`, asset.url);
    }
  });
  formData.append("thumbnail", screenshotDataUrl);

  if (isEditing.value) {
    const keptAssetIds = form.value.keptAssets.map((asset) => asset.id);
    keptAssetIds.forEach((id) => formData.append("keptAssets[]", id));
    formData.append("_method", "PUT");
  }

  try {
    let response;
    if (isEditing.value) {
      response = await store.dispatch("updateVariant", {
        id: variantId,
        value: formData,
      });
    } else {
      response = await store.dispatch("createVariant", formData);
      console.log(response);
    }
    router.push({
      name: "snippet-detail",
      params: {
        clientId: snippet.value.client_id,
        snippetId: snippet.value.id,
      },
    });
  } catch (error) {
    console.error("Error creating snippet (component): ", error);
    submissionError.value =
      error.response?.data?.message || "An unknown error has occurred.";
  } finally {
    isSubmitting.value = false;
  }
};

onMounted(async () => {
  await store.dispatch("fetchTags");

  if (isEditing.value) {
    await store.dispatch("fetchVariant", variantId);
    form.value.title = variant.value.title;
    form.value.content = variant.value.content;
    form.value.tags = variant.value.tags.map((tag) => tag.id);
    form.value.keptAssets = variant.value.assets;
  }
});

watch(
  () => form.value.content,
  () => {
    let code = document.querySelector("code");
    delete code.dataset.highlighted;
  }
);
</script>

<template>
  <form
    @submit.prevent="handleSubmit"
    class="px-4 pt-6 pb-6 bg-slate-800 max-w-2xl rounded-sm grid grid-cols-1 gap-4"
  >
    <div class="">
      <label for="title" class="block text-xs text-gray-300 mb-1.5"
        >Title</label
      >
      <input
        type="text"
        name="title"
        v-model="form.title"
        placeholder="Spanish PLCC"
        autofocus
        class="w-full px-2 py-1 rounded-sm bg-slate-600 text-paper"
      />
    </div>
    <div class="">
      <label for="title" class="block text-xs text-gray-300 mb-1.5"
        >Snippet</label
      >
      <CodeEditor
        v-model="form.content"
        theme="atom-one-dark"
        font-size="0.875rem"
        width="100%"
        :languages="[['html', 'HTML']]"
        :copy-code="false"
      ></CodeEditor>
    </div>
    <div class="">
      <label for="notes" class="block text-xs text-gray-300 mb-1.5">Tags</label>
      <div class="flex flex-row flex-wrap gap-4">
        <button
          v-for="tag in tags"
          :key="tag.id"
          :class="form.tags.includes(tag.id) ? 'bg-slate-200' : 'bg-slate-400'"
          @click.prevent="toggleTag(tag)"
          class="rounded-sm px-1.5 py-0.5 text-sm hover:bg-slate-200 duration-300 transition-colors"
        >
          {{ tag.name }}
        </button>
      </div>
    </div>
    <div class="">
      <label class="block text-xs text-gray-300 mb-1.5">Images</label>
      <label
        for="assets"
        class="rounded-sm px-3 py-1 text-sm hover:bg-slate-200 duration-300 cursor-pointer transition-colors bg-slate-400 mb-2 inline-block"
        >Upload Images</label
      >
      <input
        id="assets"
        type="file"
        accept="image/*"
        multiple
        @change="handleFiles"
        hidden
      />
      <div class="flex flex-row overflow-x-scroll gap-4">
        <div v-for="asset in assets" :key="asset.id">
          <img class="max-h-[75px] min-w-[52px]" :src="asset.url" />
          <div class="flex flex-row gap-2 mt-1">
            <span
              @click="copyAssetUrl(asset.url)"
              class="p-1 bg-slate-400 text-slate-800 hover:text-slate-950 cursor-pointer rounded-sm hover:bg-slate-600"
              ><CopyIcon
            /></span>
            <span
              @click="removeAsset(asset)"
              class="p-1 bg-slate-400 text-red-800 hover:text-red-950 cursor-pointer rounded-sm hover:bg-slate-600"
              ><TrashIcon
            /></span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="form.keptAssets.length" class="">
      <label class="block text-xs text-gray-300 mb-1.5">Existing Images</label>
      <div class="flex flex-row overflow-x-scroll gap-4">
        <div v-for="asset in form.keptAssets" :key="asset.id">
          <img class="max-h-[75px] min-w-[52px]" :src="asset.url" />
          <div class="flex flex-row gap-2 mt-1">
            <span
              @click="copyAssetUrl(asset.url)"
              class="p-1 bg-slate-400 text-slate-800 hover:text-slate-950 cursor-pointer rounded-sm hover:bg-slate-600"
              ><CopyIcon
            /></span>
            <span
              @click="removeKeptAsset(asset)"
              class="p-1 bg-slate-400 text-red-800 hover:text-red-950 cursor-pointer rounded-sm hover:bg-slate-600"
              ><TrashIcon
            /></span>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <button
        :disabled="isDisabled || isSubmitting"
        class="bg-paper text-sm px-3 py-1 rounded-sm hover:bg-slate-600 hover:text-paper duration-300 transition-colors disabled:bg-gray-400 disabled:text-gray-600"
      >
        <span v-if="!isSubmitting">Submit</span>
        <SpinnerIcon v-if="isSubmitting" class="animate-spin" />
      </button>
    </div>
    <div v-if="submissionError" class="text-red-700 text-xs text-center">
      {{ submissionError }}
    </div>
    <table
      align="center"
      role="presentation"
      cellspacing="0"
      cellpadding="0"
      border="0"
      width="600px"
      style="
        background-color: #ffffff;
        z-index: -1;
        position: absolute;
        height: auto;
        top: -100%;
        left: -100%;
      "
      v-html="form.content"
    ></table>
  </form>
</template>

<style scoped>
table img {
  display: inline;
}
</style>
