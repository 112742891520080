<!-- I don't forsee needing to edit tags, so you can't with this form at the moment. -->
<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import SpinnerIcon from "../icons/SpinnerIcon.vue";

const store = useStore();
const router = useRouter();

const form = ref({
  name: "",
});

const isSubmitting = ref(false);
const submissionError = ref("");

const isDisabled = computed(() => {
  const name = form.value.name.trim();
  return name === "";
});

const handleSubmit = async () => {
  try {
    if (isSubmitting.value) {
      return;
    }

    isSubmitting.value = true;
    submissionError.value = "";

    await store.dispatch("createTag", form.value);
    router.push("/app");
  } catch (error) {
    console.error("Error creating tag: ", error);
    submissionError.value =
      error.response?.data?.message || "An unknown error occurred.";
  } finally {
    isSubmitting.value = false;
  }
};
</script>

<template>
  <form
    @submit.prevent="handleSubmit"
    class="px-4 pt-6 pb-6 bg-slate-800 max-w-2xl rounded-sm grid grid-cols-1 md:grid-cols-2 gap-4"
  >
    <div class="">
      <label for="name" class="block text-xs text-gray-300 mb-1.5">Name</label>
      <input
        type="text"
        name="name"
        v-model="form.name"
        placeholder="Header"
        autofocus
        class="w-full px-2 py-1 rounded-sm bg-slate-600 text-paper"
      />
    </div>
    <div class="mt-0 md:mt-6">
      <button
        :disabled="isDisabled || isSubmitting"
        class="bg-paper text-sm px-3 py-1 rounded-sm hover:bg-slate-600 hover:text-paper duration-300 transition-colors disabled:bg-gray-400 disabled:text-gray-600"
      >
        <span v-if="!isSubmitting">Submit</span>
        <SpinnerIcon v-if="isSubmitting" class="animate-spin" />
      </button>
    </div>
    <div
      v-if="submissionError"
      class="text-red-500 text-xs col-span-2 text-center"
    >
      {{ submissionError }}
    </div>
  </form>
</template>
