<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";

const store = useStore();

const clients = computed(() => store.state.clients);

onMounted(async () => {
  await store.dispatch("fetchClients");
});
</script>

<template>
  <section
    class="w-52 bg-slate-800 h-screen fixed top-0 left-0 pt-16 z-10 overflow-y-scroll md:block hidden"
  >
    <p
      class="text-slate-300 text-2xl font-bold px-4 pb-2 border-b border-slate-600"
    >
      Quick Access
    </p>
    <div>
      <div class="px-4 py-2">
        <p class="text-xl font-bold text-slate-300 mb-2">Clients</p>
        <nav>
          <router-link
            v-for="client in clients"
            :key="client.id"
            :to="{ name: 'client-detail', params: { clientId: client.id } }"
            class="uppercase text-paper font-bold mb-2 px-0 py-1 rounded-sm block w-full hover:px-2 focus:px-2 hover:bg-slate-600 focus:bg-slate-600 transition-all duration-300"
            >{{ client.acronym }}</router-link
          >
        </nav>
      </div>
    </div>
  </section>
</template>
