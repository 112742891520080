<script setup>
import { defineProps } from "vue";
import { useRouter } from "vue-router";

const props = defineProps({
  client: Object,
});

const router = useRouter();

const transitionToRoute = () => {
  router.push({ name: "client-detail", params: { clientId: props.client.id } });
};
</script>

<template>
  <div
    class="bg-gradient-to-tr from-color-pink to-color-teal rounded-[3px] p-0.5"
  >
    <div
      @click="transitionToRoute"
      class="bg-slate-800 rounded-sm w-full h-full overflow-hidden flex flex-col cursor-pointer"
    >
      <div class="mb-1 bg-slate-700 p-2">
        <h3 class="text-2xl text-paper font-bold">{{ client.acronym }}</h3>
        <p class="text-slate-400 font-bold">{{ client.name }}</p>
      </div>
      <div v-if="client.recentlyCreatedSnippets.length" class="p-2">
        <p class="text-slate-400 text-xs mb-1">Recently Added</p>
        <div class="flex flex-wrap">
          <router-link
            to="#"
            v-for="snippet in client.recentlyCreatedSnippets"
            :key="snippet.id"
            class="rounded-full bg-slate-500 text-slate-800 px-2 py-0.5 text-xs mr-2 mb-2 whitespace-nowrap"
          >
            {{ snippet.title }}
          </router-link>
        </div>
      </div>
      <div v-if="client.recentlyModifiedSnippets.length" class="p-2">
        <p class="text-slate-400 text-xs mb-1">Recently Modified</p>
        <div class="flex flex-wrap">
          <router-link
            to="#"
            v-for="snippet in client.recentlyModifiedSnippets"
            :key="snippet.id"
            class="rounded-full bg-slate-500 text-slate-800 px-2 py-0.5 text-xs mr-2 mb-2 whitespace-nowrap"
          >
            {{ snippet.title }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
