<template>
  <div class="preview-container relative">
    <button
      @click="toggle(true)"
      v-if="button"
      class="absolute top-2 left-2 bg-slate-800 w-8 h-8 rounded-md md:flex items-center justify-center hover:bg-slate-950 transition-colors duration-150 hidden"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 576 512"
      >
        <path
          fill="white"
          d="M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64H240l-10.7 32H160c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H346.7L336 416H512c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64zM512 64V288H64V64H512z"
        />
      </svg>
    </button>
    <button
      @click="toggle(false)"
      v-if="button"
      class="absolute top-2 left-12 bg-slate-800 w-8 h-8 rounded-md md:flex items-center justify-center hover:bg-slate-950 transition-colors duration-150 hidden"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 384 512"
      >
        <path
          fill="white"
          d="M80 0C44.7 0 16 28.7 16 64V448c0 35.3 28.7 64 64 64H304c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H80zm80 432h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H160c-8.8 0-16-7.2-16-16s7.2-16 16-16z"
        />
      </svg>
    </button>
    <div
      class="base-html bg-slate-600 rounded-sm"
      width="100%"
      style="
        margin: 0;
        padding: 0 !important;
        mso-line-height-rule: exactly;
        min-height: 48px;
        display: flex;
        align-items: center;
      "
    >
      <table
        align="center"
        role="presentation"
        cellspacing="0"
        cellpadding="0"
        border="0"
        :style="tableStyle"
        style="width: 100%"
        class="email-container"
        v-html="htmlSnippet"
      ></table>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed, ref } from "vue";

defineProps({
  htmlSnippet: String,
  button: Boolean,
});

const desktop = ref(true);

const tableStyle = computed(() => ({
  maxWidth: desktop.value ? "600px" : "380px",
  margin: "0 auto",
  backgroundColor: "#ffffff",
}));

const toggle = (bool) => {
  desktop.value = bool;
};
</script>

<style>
table {
  transition: max-width 0.5s ease-in-out; /* Adjust the timing and easing function as needed */
}
table img {
  display: inline;
}
</style>
