<script setup>
import { defineEmits, defineProps } from "vue";

defineProps({
  item: String,
});

const emit = defineEmits(["confirm", "cancel"]);

const confirmDeletion = () => {
  emit("confirm");
};

const cancelDeletion = () => {
  emit("cancel");
};
</script>

<template>
  <div
    class="fixed h-screen w-screen bg-black bg-opacity-40 inset-0 z-50 flex justify-center items-center"
  >
    <div
      class="bg-gradient-to-tr z-[51] from-color-pink to-color-teal rounded-[3px] p-0.5 m-2"
    >
      <div class="z-[51] bg-slate-800 p-8 rounded-sm max-w-sm">
        <p class="text-2xl text-paper font-bold">
          Confirm Deletion of {{ item }}
        </p>
        <div class="flex gap-4 flex-row mt-4">
          <button
            @click.prevent="confirmDeletion"
            class="px-3 py-1 text-sm bg-red-800 text-white rounded-sm hover:bg-red-900 duration-300 transition-colors cursor-pointer"
          >
            Delete
          </button>
          <button
            @click.prevent="cancelDeletion"
            class="px-3 py-1 text-sm bg-paper rounded-sm hover:bg-slate-300 duration-300 transition-colors cursor-pointer"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
