import { createStore } from "vuex";
import axios from "axios";
import router from "@/router";

axios.defaults.baseURL = "https://api.quicksnips.dev/api";

export default createStore({
  state: {
    user: null,
    token: localStorage.getItem("token") || null,
    clients: [],
    currentClient: {},
    tags: [],
    currentSnippet: {},
    currentVariant: {},
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    currentUser: (state) => state.user,
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    LOGOUT(state) {
      state.user = null;
      state.token = null;
    },
    SET_CLIENTS(state, clients) {
      state.clients = clients;
    },
    SET_CLIENT(state, client) {
      state.currentClient = client;
    },
    ADD_CLIENT(state, client) {
      state.clients.push(client);
    },
    UPDATE_CLIENT(state, updatedClient) {
      const clientIndex = state.clients.findIndex(
        (client) => client.id == updatedClient.id
      );
      if (clientIndex != -1) {
        state.clients[clientIndex] = updatedClient;
      }
    },
    DELETE_CLIENT(state, clientId) {
      const clientIndex = state.clients.findIndex(
        (client) => client.id == clientId
      );
      if (clientIndex != -1) {
        state.clients.splice(clientIndex, 1);
      }
    },
    SET_TAGS(state, tags) {
      state.tags = tags;
    },
    ADD_TAG(state, tag) {
      state.tags.push(tag);
    },
    SET_SNIPPET(state, snippet) {
      state.currentSnippet = snippet;
    },
    ADD_SNIPPET() {
      // Have to redo the stuff server side for recently created and modified snippets, if this becomes a performance drain, will fix.
    },
    UPDATE_SNIPPET() {
      // Have to redo the stuff server side for recently created and modified snippets, if this becomes a performance drain, will fix.
    },
    DELETE_SNIPPET(state, { snippetId, clientId }) {
      const clientIndex = state.clients.findIndex(
        (client) => client.id == clientId
      );
      if (clientIndex != -1) {
        const snippetIndex = state.clients[clientIndex].snippets.findIndex(
          (snip) => snip.id == snippetId
        );
        if (snippetIndex != -1) {
          state.clients[clientIndex].snippets.splice(snippetIndex, 1);
        }
      }
    },
    SET_SNIPPET_PRIMARY(state, snippet) {
      state.currentSnippet = snippet;
    },
    SET_VARIANT(state, variant) {
      state.currentVariant = variant;
    },
    ADD_VARIANT() {
      // idk
    },
    UPDATE_VARIANT(state, variant) {
      state.currentVariant = variant;
    },
    DELETE_VARIANT(state, variantId) {
      const variantIndex = state.currentSnippet.variants.findIndex(
        (variant) => variant.id == variantId
      );
      if (variantIndex != -1) {
        state.currentSnippet.variants.splice(variantIndex, 1);
      }
    },
    DOWNLOAD_VARIANT() {
      // idk
    },
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const response = await axios.post("/login", credentials);
        const token = response.data.token;
        const user = response.data.user;
        localStorage.setItem("token", token);
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        commit("SET_TOKEN", token);
        commit("SET_USER", user);
      } catch (error) {
        localStorage.removeItem("token");
        throw error;
      }
    },
    async logout({ commit }) {
      try {
        await axios.post("/logout");
        commit("LOGOUT");
        localStorage.removeItem("token");
        delete axios.defaults.headers.common["Authorization"];
      } catch (error) {
        console.error("Error logging out: ", error);
      }
    },
    async initializeAuth({ commit }) {
      if (this.state.token) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + this.state.token;
        try {
          const response = await axios.get("/user");
          commit("SET_USER", response.data);
        } catch (error) {
          commit("LOGOUT");
          console.error("Error initializing auth: ", error);
        }
      }
    },
    async fetchClients({ commit }) {
      try {
        const response = await axios.get("/clients");
        commit("SET_CLIENTS", response.data);
      } catch (error) {
        console.error("Error fetching clients: ", error);
      }
    },
    async fetchClient({ commit }, clientId) {
      try {
        const response = await axios.get(`/clients/${clientId}`);
        commit("SET_CLIENT", response.data);
      } catch (error) {
        console.error("Error fetching client: ", error);
      }
    },
    async createClient({ commit }, client) {
      const response = await axios.post("/clients", client);
      commit("ADD_CLIENT", response.data);
      return response;
    },
    async updateClient({ commit }, { id: clientId, value: client }) {
      const response = await axios.put(`/clients/${clientId}`, client);
      commit("UPDATE_CLIENT", response.data);
      return response;
    },
    async deleteClient({ commit }, clientId) {
      try {
        await axios.delete(`/clients/${clientId}`);
        commit("DELETE_CLIENT", clientId);
        router.push({ name: "app" });
      } catch (error) {
        console.error("Error deleting client: ", error);
      }
    },
    async fetchTags({ commit }) {
      try {
        const response = await axios.get("/tags");
        commit("SET_TAGS", response.data);
      } catch (error) {
        console.error("Error fetching tags: ", error);
      }
    },
    async createTag({ commit }, tag) {
      const response = await axios.post("/tags", tag);
      commit("ADD_TAG", response.data);
      return response;
    },
    async fetchSnippet({ commit }, snippetId) {
      try {
        const response = await axios.get(`/snippets/${snippetId}`);
        commit("SET_SNIPPET", response.data);
      } catch (error) {
        console.error("Error fetching snippet: ", error);
      }
    },
    async createSnippet({ commit }, snippet) {
      const response = await axios.post("/snippets", snippet);
      commit("ADD_SNIPPET", snippet);
      this.dispatch("fetchClients");
      return response;
    },
    async updateSnippet({ commit }, { id: snippetId, value: snippet }) {
      const response = await axios.put(`/snippets/${snippetId}`, snippet);
      commit("UPDATE_SNIPPET", response.data);
      this.dispatch("fetchClients");
      return response;
    },
    async deleteSnippet({ commit }, { snippetId, clientId }) {
      console.log(snippetId, clientId);
      try {
        await axios.delete(`/snippets/${snippetId}`);
        commit("DELETE_SNIPPET", snippetId);
        router.push({ name: "client-detail", params: { clientId: clientId } });
      } catch (error) {
        console.error("Error deleting snippet: ", error);
      }
    },
    async setSnippetPrimary({ commit }, { snippetId, value }) {
      try {
        const response = await axios.patch(
          `/snippets/${snippetId}/primary`,
          value
        );
        commit("SET_SNIPPET_PRIMARY", response.data);
      } catch (error) {
        console.error("Error setting primary variant: ", error);
      }
    },
    async fetchVariant({ commit }, variantId) {
      try {
        const response = await axios.get(`/variants/${variantId}`);
        commit("SET_VARIANT", response.data);
      } catch (error) {
        console.error("Error fetching variant: ", error);
      }
    },
    async createVariant({ commit }, variant) {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post("/variants", variant, config);
      commit("ADD_VARIANT", response);
      return response;
    },
    async updateVariant({ commit }, { id, value }) {
      const response = await axios.post(`/variants/${id}`, value);
      commit("UPDATE_VARIANT", response);
      return response;
    },
    async deleteVariant({ commit }, variantId) {
      try {
        await axios.delete(`/variants/${variantId}`);
        commit("DELETE_VARIANT", variantId);
      } catch (error) {
        console.error("Error deleting variant: ", error);
      }
    },
    async downloadVariant({ commit }, variantId) {
      const response = await axios({
        url: `/variants/${variantId}/download`,
        method: "GET",
        responseType: "blob",
      });
      commit("DOWNLOAD_VARIANT");
      return response;
    },
  },
});
