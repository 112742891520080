import { createRouter, createWebHistory } from "vue-router";
import LoginView from "@/views/LoginView.vue";
import AppView from "@/views/AppView";
import ClientAddView from "@/views/client/ClientAddView.vue";
import ClientDetailView from "@/views/client/ClientDetailView.vue";
import ClientEditView from "@/views/client/ClientEditView.vue";
import TagAddView from "@/views/tag/TagAddView.vue";
import SnippetDetailView from "@/views/snippet/SnippetDetailView.vue";
import SnippetAddView from "@/views/snippet/SnippetAddView.vue";
import SnippetEditView from "@/views/snippet/SnippetEditView.vue";
import VariantAddView from "@/views/variant/VariantAddView.vue";
import VariantEditView from "@/views/variant/VariantEditView.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import store from "@/store";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/app",
    component: AuthLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: "app",
        component: AppView,
      },
      {
        path: "client",
        children: [
          {
            path: ":clientId",
            name: "client-detail",
            component: ClientDetailView,
          },
          {
            path: "add",
            name: "client-add",
            component: ClientAddView,
          },
          {
            path: ":clientId/edit",
            name: "client-edit",
            component: ClientEditView,
          },
          {
            path: ":clientId/snippet/:snippetId",
            name: "snippet-detail",
            component: SnippetDetailView,
          },
          {
            path: ":clientId/snippet/add",
            name: "snippet-add",
            component: SnippetAddView,
          },
          {
            path: ":clientId/snippet/:snippetId/edit",
            name: "snippet-edit",
            component: SnippetEditView,
          },
          {
            path: ":clientId/snippet/:snippetId/variant/add",
            name: "variant-add",
            component: VariantAddView,
          },
          {
            path: ":clientId/snippet/:snippetId/variant/:variantId/edit",
            name: "variant-edit",
            component: VariantEditView,
          },
        ],
      },
      {
        path: "tag/add",
        name: "tag-add",
        component: TagAddView,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isLoggedIn = store.getters.isLoggedIn;

  if (requiresAuth && !isLoggedIn) {
    next("/login");
  } else if (to.path === "/login" && isLoggedIn) {
    next("/app");
  } else {
    next();
  }
});

export default router;
