<script setup>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import VariantCard from "@/components/ui/VariantCard.vue";
import VariantMenuItem from "@/components/ui/VariantMenuItem.vue";

import PencilIcon from "@/components/icons/PencilIcon.vue";
import TrashIcon from "@/components/icons/TrashIcon.vue";
import DeleteConfirmation from "@/components/ui/DeleteConfirmation.vue";

const store = useStore();
const route = useRoute();

const snippet = computed(() => store.state.currentSnippet);

const showDeleteConfirmation = ref(false);
const selectedVariantId = ref(null);
const variantId = ref(null);

const selectedVariant = computed(() => {
  if (snippet.value.variants) {
    console.log();
    return snippet.value.variants.find(
      (variant) => variant.id === variantId.value
    );
  } else {
    return null;
  }
});

const formattedUpdatedAt = computed(() => {
  if (snippet.value.updated_at) {
    const date = new Date(snippet.value.updated_at);
    return date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
  return "";
});

const deleteSnippet = async () => {
  await store.dispatch("deleteSnippet", {
    snippetId: snippet.value.id,
    clientId: snippet.value.client_id,
  });
};

const handleVariantDelete = (id) => {
  selectedVariantId.value = id;
  showDeleteConfirmation.value = true;
};

const deleteVariant = async () => {
  console.log("test");
  if (selectedVariantId.value) {
    await store.dispatch("deleteVariant", selectedVariantId.value);
    selectedVariantId.value = null;
    showDeleteConfirmation.value = false;
    variantId.value = snippet.value.variants[0];
  }
};

watch(
  () => route.params.snippetId,
  async (newId) => {
    if (newId) {
      await store.dispatch("fetchSnippet", newId);
      variantId.value = snippet.value.variants[0].id;
    }
  },
  { immediate: true }
);
</script>

<template>
  <section>
    <div>
      <router-link
        :to="{ name: 'client-detail', params: { clientId: snippet.client_id } }"
        class="block font-bold text-xl text-slate-500"
        >{{ snippet.client?.acronym }}</router-link
      >
      <h1 class="text-4xl font-bold leading-8 mb-2">{{ snippet.title }}</h1>
      <p class="text-sm pl-[1px] text-slate-500">
        Last modified
        <span v-if="snippet.last_modified_user">
          by {{ snippet.last_modified_user.name }}</span
        >
        on {{ formattedUpdatedAt }}
      </p>
    </div>
    <div class="flex flex-row gap-3 mt-3">
      <router-link
        :to="{ name: 'snippet-edit', params: { snippetId: snippet.id } }"
        class="p-2 w-8 h-8 bg-slate-800 text-paper rounded-sm hover:bg-slate-600 duration-300 transition-colors"
      >
        <PencilIcon class="mx-auto" />
      </router-link>
      <button
        class="p-2 w-8 h-8 bg-red-800 text-paper rounded-sm hover:bg-red-900 duration-300 transition-colors"
        @click="showDeleteConfirmation = true"
      >
        <TrashIcon class="mx-auto" />
      </button>
      <router-link
        :to="{
          name: 'variant-add',
          params: { clientId: snippet.client_id, snippetId: snippet.id },
        }"
        class="p-2 h-8 bg-slate-800 text-paper rounded-sm hover:bg-slate-600 duration-300 transition-colors text-sm flex items-center"
      >
        Create Variant
      </router-link>
    </div>
    <hr class="my-3 border-slate-300" />
    <div class="flex flex-row gap-3"></div>
    <div class="flex flex-col lg:flex-row-reverse gap-4">
      <div class="flex-grow">
        <div class="block lg:hidden">
          <select
            v-model="variantId"
            class="px-2 py-1 rounded-sm bg-slate-800 text-white pr-1"
          >
            <option
              v-for="variant in snippet.variants"
              :key="variant.id"
              :value="variant.id"
            >
              {{ variant.title }}
            </option>
          </select>
        </div>
        <div class="hidden lg:grid lg:grid-cols-1 gap-4">
          <VariantMenuItem
            v-for="variant in snippet.variants"
            :key="variant.id"
            :variant="variant"
            @click="variantId = variant.id"
            :variantId="variantId"
            class="max-w-[250px]"
          />
        </div>
      </div>
      <div class="max-w-4xl w-full">
        <VariantCard
          v-if="selectedVariant"
          :variant="selectedVariant"
          :snippet="snippet"
          @delete="handleVariantDelete"
        />
      </div>
    </div>
    <DeleteConfirmation
      v-if="showDeleteConfirmation"
      @confirm="selectedVariantId ? deleteVariant() : deleteSnippet()"
      @cancel="showDeleteConfirmation = false"
      :item="selectedVariantId ? 'variant' : snippet.title"
    />
  </section>
</template>
