<script setup>
import { ref, onMounted, onUnmounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import CodeIcon from "@/components/icons/CodeIcon.vue";
import AngleIcon from "@/components/icons/AngleIcon.vue";
import HamburgerMenuIcon from "@/components/icons/HamburgerMenuIcon.vue";

const store = useStore();
const router = useRouter();

const showDropdown = ref(false);
const dropdown = ref(null);
const dropdownTrigger = ref(null);
const showProfile = ref(false);
const profile = ref(null);
const profileTrigger = ref(null);
const showMobileMenu = ref(false);
const mobileMenu = ref(null);
const mobileMenuTrigger = ref(null);

const user = computed(() => store.getters.currentUser);

const clickOutsideDropdown = (e) => {
  if (
    showDropdown.value &&
    !dropdown.value.contains(e.target) &&
    !dropdownTrigger.value.contains(e.target)
  ) {
    showDropdown.value = false;
  }
  if (
    showProfile.value &&
    !profile.value.contains(e.target) &&
    !profileTrigger.value.contains(e.target)
  ) {
    showProfile.value = false;
  }
  if (
    showMobileMenu.value &&
    !mobileMenu.value.contains(e.target) &&
    !mobileMenuTrigger.value.contains(e.target)
  ) {
    showMobileMenu.value = false;
  }
};

const logout = async () => {
  await store.dispatch("logout");
  router.push("/login");
};

onMounted(() => {
  document.addEventListener("click", clickOutsideDropdown);
});

onUnmounted(() => {
  document.removeEventListener("click", clickOutsideDropdown);
});
</script>

<template>
  <section
    class="bg-slate-900 py-2 px-4 h-14 flex flex-row gap-8 justify-between w-full top-0 items-center sm:fixed relative z-20"
  >
    <router-link to="/app" class="inline-flex flex-row gap-3 items-center">
      <CodeIcon class="h-7" />
      <span class="text-3xl text-white font-bold">Quicksnips</span>
    </router-link>
    <div class="flex-row flex-grow gap-3 items-center hidden sm:inline-flex">
      <router-link
        to="/app"
        class="text-paper mt-0.5 font-bold px-3 py-1 hover:text-white"
        >Dashboard</router-link
      >
      <div class="relative">
        <button
          @click.prevent="showDropdown = !showDropdown"
          ref="dropdownTrigger"
          class="text-paper mt-0.5 font-bold px-3 py-1 hover:text-white"
        >
          Create&nbsp;&nbsp;<AngleIcon
            :class="showDropdown ? 'rotate-0' : 'rotate-180'"
            class="inline transition-transform duration-50"
          />
        </button>
        <nav
          v-show="showDropdown"
          ref="dropdown"
          class="absolute top-[145%] left-0 bg-slate-900 text-paper min-w-[97px] overflow-hidden rounded-sm"
        >
          <router-link
            :to="{ name: 'client-add' }"
            @click.prevent="showDropdown = !showDropdown"
            class="block py-1 px-2 w-full font-bold text-sm hover:bg-slate-700 hover:text-white transition-colors duration-300"
            >Client</router-link
          >
          <router-link
            to="#"
            @click.prevent="showDropdown = !showDropdown"
            class="block py-1 px-2 w-full font-bold text-sm hover:bg-slate-700 hover:text-white transition-colors duration-300"
            >Snippet</router-link
          >
          <router-link
            :to="{ name: 'tag-add' }"
            @click.prevent="showDropdown = !showDropdown"
            class="block py-1 px-2 w-full font-bold text-sm hover:bg-slate-700 hover:text-white transition-colors duration-300"
            >Tag</router-link
          >
        </nav>
      </div>
      <div class="relative ml-auto">
        <button
          @click.prevent="showProfile = !showProfile"
          ref="profileTrigger"
          class="text-paper mt-0.5 font-bold px-3 py-1 hover:text-white"
        >
          {{ user?.name }}
        </button>
        <nav
          v-show="showProfile"
          ref="profile"
          class="absolute top-[145%] right-3 bg-slate-900 text-paper min-w-[97px] overflow-hidden rounded-sm"
        >
          <button
            to="#"
            @click="logout"
            class="block py-1 px-2 w-full text-left font-bold text-sm hover:bg-slate-700 hover:text-white transition-colors duration-300"
          >
            Logout
          </button>
        </nav>
      </div>
    </div>
    <div
      class="block sm:hidden text-paper"
      @click="showMobileMenu = !showMobileMenu"
      ref="mobileMenuTrigger"
    >
      <HamburgerMenuIcon class="h-7" />
    </div>
    <nav
      v-show="showMobileMenu"
      class="block sm:hidden absolute top-full left-0 w-screen bg-slate-800 py-2 text-paper"
      ref="mobileMenu"
    >
      <div class="border-b border-slate-600 px-4 pb-2">
        <router-link
          to="/app"
          @click.prevent="showMobileMenu = !showMobileMenu"
          class="block py-1 pr-2 w-full font-bold text-sm hover:bg-slate-700 hover:text-white transition-colors duration-300"
          >Dashboard</router-link
        >
      </div>
      <div class="border-b border-slate-600 px-4 py-2">
        <div class="text-2xl font-bold mb-2">Create</div>
        <router-link
          :to="{ name: 'client-add' }"
          @click.prevent="showMobileMenu = !showMobileMenu"
          class="block py-2 pr-2 w-full font-bold text-sm hover:bg-slate-700 hover:text-white transition-colors duration-300"
          >Client</router-link
        >
        <router-link
          to="#"
          @click.prevent="showMobileMenu = !showMobileMenu"
          class="block py-1 pr-2 w-full font-bold text-sm hover:bg-slate-700 hover:text-white transition-colors duration-300"
          >Snippet</router-link
        >
        <router-link
          to="#"
          @click.prevent="showMobileMenu = !showMobileMenu"
          class="block py-1 pr-2 w-full font-bold text-sm hover:bg-slate-700 hover:text-white transition-colors duration-300"
          >Tag</router-link
        >
      </div>
      <div class="px-4 pt-2">
        <div class="text-2xl font-bold mb-2">{{ user?.name }}</div>
        <button
          @click="logout"
          class="block py-1 pr-2 w-full text-left font-bold text-sm hover:bg-slate-700 hover:text-white transition-colors duration-300"
        >
          Logout
        </button>
      </div>
    </nav>
  </section>
</template>
