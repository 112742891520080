<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import VariantForm from "@/components/forms/VariantForm.vue";

const store = useStore();
const route = useRoute();

const snippet = computed(() => store.state.currentSnippet);

onMounted(async () => {
  await store.dispatch("fetchSnippet", route.params.snippetId);
  console.log(snippet.value);
});
</script>

<template>
  <div>
    <h1 class="text-4xl font-bold mb-4">
      Create New Variant in {{ snippet.title }}
    </h1>
    <VariantForm :snippet="snippet" />
  </div>
</template>
