<template>
  <router-view />
</template>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #f2f2f2;
}
.code-editor[theme="atom-one-dark"] {
  border-radius: 2px !important;
}

.code-editor[theme="atom-one-dark"] * {
  background: rgb(71 85 105 / var(--tw-bg-opacity));
}

.code-editor[theme="atom-one-dark"] .hljs {
  border-radius: 2px !important;
}

.code-editor[theme="atom-one-dark"] .header {
  border-radius: 2px 2px 0 0 !important;
}

.code-editor[theme="atom-one-dark"] .code-area {
  border-radius: 0 0 2px 2px !important;
}

.code-editor[theme="atom-one-dark"]:focus-within {
  outline: 2px solid white;
}
</style>
