<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import SnippetForm from "@/components/forms/SnippetForm.vue";

const store = useStore();
const route = useRoute();

const client = computed(() => store.state.currentClient);
const snippet = computed(() => store.state.currentSnippet);

onMounted(async () => {
  await store.dispatch("fetchClient", route.params.clientId);
});
</script>

<template>
  <div>
    <h1 class="text-4xl font-bold mb-4">Edit {{ snippet.title }}</h1>
    <SnippetForm :client="client" />
  </div>
</template>
