<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import CodeIcon from "@/components/icons/CodeIcon.vue";
import SpinnerIcon from "@/components/icons/SpinnerIcon.vue";
import PositiveEyeIcon from "@/components/icons/PositiveEyeIcon.vue";
import NegativeEyeIcon from "@/components/icons/NegativeEyeIcon.vue";

const store = useStore();
const router = useRouter();

const form = ref({
  email: "",
  password: "",
});

const revealPassword = ref(false);
const isLoggingIn = ref(false);
const submissionError = ref("");

const isLoginDisabled = computed(() => {
  let email = form.value.email.trim();
  let password = form.value.password;
  return email === "" || password === "";
});

const handleSubmit = async () => {
  if (isLoggingIn.value) {
    return;
  }

  isLoggingIn.value = true;
  submissionError.value = false;

  try {
    await store.dispatch("login", form.value);
    router.push("/app");
  } catch (error) {
    submissionError.value =
      error.response?.data?.message || "An unknown error occurred";
  } finally {
    isLoggingIn.value = false;
  }
};
</script>

<template>
  <main>
    <section
      class="w-screen min-h-[calc(100dvh)] flex items-center justify-center relative"
    >
      <div class="grid grid-cols-1 justify-items-center z-20">
        <div class="inline-flex gap-3 items-center flex-row mb-6 md:mb-12">
          <CodeIcon class="md:mt-0.5 h-5 md:h-7" />
          <h1 class="font-bold text-xl md:text-3xl">Quicksnips</h1>
        </div>
        <h3 class="font-bold text-center text-3xl mb-4 md:mb-8 md:text-5xl">
          Welcome Back
        </h3>
        <div
          class="px-4 py-6 mx-2 bg-white shadow-lg sm:min-w-[400px] min-w-[280px]"
        >
          <form @submit.prevent="handleSubmit" class="flex flex-col gap-6">
            <div class="">
              <label for="email" class="block text-xs mb-2 text-gray-600"
                >Email</label
              >
              <input
                type="email"
                v-model="form.email"
                name="email"
                placeholder="John.Doe@provider.com"
                autofocus
                class="border p-2 border-gray-600 w-full bg-paper rounded-sm outline-none text-sm focus:bg-white focus:shadow-lg"
              />
            </div>
            <div class="relative">
              <label for="password" class="block text-xs mb-2 text-gray-600"
                >Password</label
              >
              <input
                :type="revealPassword ? 'text' : 'password'"
                v-model="form.password"
                name="password"
                placeholder="············"
                class="border p-2 border-gray-600 w-full bg-paper rounded-sm outline-none text-sm focus:bg-white focus:shadow-lg"
              />
              <PositiveEyeIcon
                @click="revealPassword = true"
                v-if="revealPassword == false"
                class="absolute z-30 right-2.5 bottom-2.5 cursor-pointer text-gray-600 hover:text-black duration-300 transition-colors"
              />
              <NegativeEyeIcon
                @click="revealPassword = false"
                v-if="revealPassword == true"
                class="absolute z-30 pr-[1px] right-2 bottom-2.5 cursor-pointer text-gray-600 hover:text-black duration-300 transition-colors"
              />
            </div>
            <div class="my-2">
              <button
                :disabled="isLoggingIn || isLoginDisabled"
                class="rounded-sm px-3 py-1 bg-sky-600 hover:bg-sky-900 duration-300 transition-colors outline-none focus:bg-sky-900 focus:shadow-lg text-white mx-auto block disabled:bg-gray-500 disabled:cursor-not-allowed"
              >
                <span v-if="!isLoggingIn">Login</span>
                <SpinnerIcon v-if="isLoggingIn" class="animate-spin" />
              </button>
            </div>
            <div
              v-if="submissionError"
              class="text-red-700 text-xs text-center px-8"
            >
              {{ submissionError }}
            </div>
          </form>
        </div>
      </div>
    </section>
  </main>
</template>
