<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
  snippet: Object,
});

const relativeTime = computed(() => {
  return toRelativeTime(props.snippet.updated_at);
});

const toRelativeTime = (dateString) => {
  const now = new Date();
  const date = new Date(dateString);
  const diffInSeconds = Math.floor((now - date) / 1000);

  let interval = Math.floor(diffInSeconds / (60 * 60 * 24 * 30 * 12));
  if (interval >= 1) return `${interval} year${interval === 1 ? "" : "s"} ago`;

  interval = Math.floor(diffInSeconds / (60 * 60 * 24 * 30));
  if (interval >= 1) return `${interval} month${interval === 1 ? "" : "s"} ago`;

  interval = Math.floor(diffInSeconds / (60 * 60 * 24));
  if (interval >= 1) return `${interval} day${interval === 1 ? "" : "s"} ago`;

  interval = Math.floor(diffInSeconds / (60 * 60));
  if (interval >= 1) return `${interval} hour${interval === 1 ? "" : "s"} ago`;

  interval = Math.floor(diffInSeconds / 60);
  if (interval >= 1)
    return `${interval} minute${interval === 1 ? "" : "s"} ago`;

  return "just now";
};
</script>

<template>
  <router-link
    :to="{
      name: 'snippet-detail',
      params: { clientId: snippet.client_id, snippetId: snippet.id },
    }"
    class="bg-gradient-to-tr from-color-pink to-color-teal rounded-[3px] p-0.5"
  >
    <div
      class="bg-slate-800 rounded-sm w-full h-full overflow-hidden flex flex-col cursor-pointer"
    >
      <div class="bg-slate-700 p-2">
        <h3 class="text-2xl text-paper font-bold mb-1 ml-0.5">
          {{ snippet.title }}
        </h3>
        <div class="flex flex-row flex-wrap gap-2">
          <span
            v-for="tag in snippet.tags"
            :key="tag.id"
            class="text-xs w-fit px-2 py-0.5 bg-gray-500 rounded-full text-slate-800 font-bold"
            >{{ tag.name }}</span
          >
        </div>
      </div>
      <img
        v-if="snippet.primary_variant_id"
        :src="snippet.primary_variant.thumbnail.url"
      />
      <div>
        <div
          class="rounded-full bg-gray-500 text-slate-800 px-2 py-0.5 m-2 text-xs w-fit whitespace-nowrap mt-3"
        >
          <strong>Last Modified</strong>
          {{ relativeTime }}
          <span v-if="snippet.last_modified_user">
            by {{ snippet.last_modified_user.name }}</span
          >
        </div>
      </div>
    </div>
  </router-link>
</template>
