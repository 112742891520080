<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

import ClientCard from "@/components/ui/ClientCard.vue";

const store = useStore();

const clients = computed(() => store.state.clients);
</script>

<template>
  <section>
    <h1 class="font-bold text-4xl">Clients</h1>
    <div
      class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 mt-4"
    >
      <ClientCard v-for="client in clients" :key="client.id" :client="client" />
    </div>
  </section>
</template>
