<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import SnippetCard from "@/components/ui/SnippetCard.vue";

import PencilIcon from "@/components/icons/PencilIcon.vue";
import TrashIcon from "@/components/icons/TrashIcon.vue";
import DeleteConfirmation from "@/components/ui/DeleteConfirmation.vue";

const store = useStore();
const route = useRoute();
const router = useRouter();

const client = computed(() => store.state.currentClient);

const showDeleteConfirmation = ref(false);
const selectedTag = ref(null);

const sortedByTag = computed(() => {
  if (!client.value.snippets) {
    return;
  }

  const snippets = client.value.snippets;
  snippets.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

  let result = [];

  if (selectedTag.value) {
    result = snippets.filter(
      (snippet) =>
        snippet.tags &&
        snippet.tags.some((tag) => tag.id === selectedTag.value.id)
    );
  } else {
    result = snippets;
  }

  return result;
});

const availableTags = computed(() => {
  if (!client.value.snippets) {
    return;
  }

  let available = [];
  client.value.snippets.forEach((snippet) => {
    if (snippet.tags) {
      snippet.tags.forEach((tag) => {
        if (!available.some((t) => t.id === tag.id)) {
          available.push(tag);
        }
      });
    }
  });
  return available;
});

const formattedUpdatedAt = computed(() => {
  if (client.value.updated_at) {
    const date = new Date(client.value.updated_at);
    return date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
  return "";
});

const selectTag = (tag) => {
  if (selectedTag.value?.id == tag.id) {
    selectedTag.value = null;
  } else {
    selectedTag.value = tag;
  }
};

const deleteClient = async () => {
  await store.dispatch("deleteClient", client.value.id);
  router.push("/app");
};

onMounted(async () => {
  // await store.dispatch("fetchClient", route.params.clientId);
});

watch(
  () => route.params.clientId,
  async (newId) => {
    if (newId) {
      await store.dispatch("fetchClient", newId);
    }
  },
  { immediate: true }
);
</script>

<template>
  <section>
    <div>
      <span class="block font-bold text-xl text-slate-500">{{
        client.acronym
      }}</span>
      <h1 class="text-4xl font-bold leading-8 mb-2">{{ client.name }}</h1>
      <p class="text-sm pl-[1px] text-slate-500">
        Last modified
        <span v-if="client.last_modified_user">
          by {{ client.last_modified_user.name }}</span
        >
        on {{ formattedUpdatedAt }}
      </p>
    </div>
    <div class="flex flex-row gap-3 mt-3">
      <router-link
        :to="{ name: 'client-edit', params: { clientId: client.id } }"
        class="p-2 w-8 h-8 bg-slate-800 text-paper rounded-sm hover:bg-slate-600 duration-300 transition-colors"
      >
        <PencilIcon class="mx-auto" />
      </router-link>
      <button
        class="p-2 w-8 h-8 bg-red-800 text-paper rounded-sm hover:bg-red-900 duration-300 transition-colors"
        @click="showDeleteConfirmation = true"
      >
        <TrashIcon class="mx-auto" />
      </button>
      <router-link
        :to="{ name: 'snippet-add', params: { clientId: client.id } }"
        class="p-2 h-8 bg-slate-800 text-paper rounded-sm hover:bg-slate-600 duration-300 transition-colors text-sm flex items-center"
      >
        Create Snippet
      </router-link>
    </div>
    <hr class="my-3 border-slate-300" />
    <div class="flex flex-row gap-3">
      <button
        v-for="tag in availableTags"
        :key="tag.id"
        :class="
          selectedTag == tag
            ? 'bg-slate-400 text-black'
            : 'bg-slate-600 text-paper'
        "
        @click="selectTag(tag)"
        class="rounded-sm px-1.5 py-0.5 text-sm hover:bg-slate-400 hover:text-black duration-300 transition-colors"
      >
        {{ tag.name }}
      </button>
    </div>
    <div class="parent">
      <SnippetCard
        :snippet="snippet"
        v-for="snippet in sortedByTag"
        :key="snippet.id"
        class="child"
      />
    </div>
    <DeleteConfirmation
      v-if="showDeleteConfirmation"
      @confirm="deleteClient"
      @cancel="showDeleteConfirmation = false"
      :item="client.name"
    />
  </section>
</template>

<style scoped>
.parent {
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px;
  -moz-column-fill: balance;
  column-fill: balance;
}

.child {
  width: calc(100% - 4px);
  margin: 10px 0px;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .parent {
    -webkit-columns: 250px 3;
    -moz-columns: 250px 3;
    columns: 250px 3;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
  .parent {
    -webkit-columns: 200px 2;
    -moz-columns: 200px 2;
    columns: 200px 2;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767px) {
  .parent {
    -webkit-columns: 100% 1;
    -moz-columns: 100% 1;
    columns: 100% 1;
  }
}
</style>
