<script setup>
import { defineProps, computed, defineEmits, watch } from "vue";
import { useStore } from "vuex";
// import { useRoute } from "vue-router";

import CodeEditor from "simple-code-editor";

import HtmlPreview from "@/components/ui/HtmlPreview.vue";

import PencilIcon from "../icons/PencilIcon.vue";
import TrashIcon from "../icons/TrashIcon.vue";
import StarIcon from "../icons/StarIcon.vue";
import StarOpenIcon from "../icons/StarOpenIcon.vue";
import DownloadIcon from "../icons/DownloadIcon.vue";

// const route = useRoute();
const store = useStore();

const props = defineProps({
  variant: Object,
  snippet: Object,
});

const emits = defineEmits(["delete"]);

// const clientId = route.params.clientId;
// const snippetId = route.params.snippetId;

const relativeTime = computed(() => {
  return toRelativeTime(props.variant.updated_at);
});

const emitDelete = (id) => {
  emits("delete", id);
};

const setPrimary = async () => {
  if (props.snippet.primary_variant_id == props.variant.id) {
    return;
  }
  const form = {
    primary_variant_id: props.variant.id,
  };
  await store.dispatch("setSnippetPrimary", {
    snippetId: props.snippet.id,
    variantId: props.variant.id,
    value: form,
  });
};

const download = async () => {
  const response = await store.dispatch("downloadVariant", props.variant.id);

  const url = URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "archive.zip");
  document.body.appendChild(link);
  link.click();

  link.parentNode.removeChild(link);
  URL.revokeObjectURL(url);
};

const toRelativeTime = (dateString) => {
  const now = new Date();
  const date = new Date(dateString);
  const diffInSeconds = Math.floor((now - date) / 1000);

  let interval = Math.floor(diffInSeconds / (60 * 60 * 24 * 30 * 12));
  if (interval >= 1) return `${interval} year${interval === 1 ? "" : "s"} ago`;

  interval = Math.floor(diffInSeconds / (60 * 60 * 24 * 30));
  if (interval >= 1) return `${interval} month${interval === 1 ? "" : "s"} ago`;

  interval = Math.floor(diffInSeconds / (60 * 60 * 24));
  if (interval >= 1) return `${interval} day${interval === 1 ? "" : "s"} ago`;

  interval = Math.floor(diffInSeconds / (60 * 60));
  if (interval >= 1) return `${interval} hour${interval === 1 ? "" : "s"} ago`;

  interval = Math.floor(diffInSeconds / 60);
  if (interval >= 1)
    return `${interval} minute${interval === 1 ? "" : "s"} ago`;

  return "just now";
};

watch(
  () => props.variant.content,
  () => {
    let code = document.querySelector("code");
    delete code.dataset.highlighted;
  }
);
</script>

<template>
  <div
    class="bg-gradient-to-tr from-color-pink to-color-teal rounded-[3px] p-0.5"
  >
    <div
      class="bg-slate-800 rounded-sm w-full h-full overflow-hidden flex flex-col"
    >
      <div class="bg-slate-700 p-2 flex flex-row justify-between">
        <div>
          <h3 class="text-2xl text-paper font-bold mb-1 ml-0.5">
            {{ variant.title }}
          </h3>
          <p class="text-xs text-slate-300 ml-0.5">
            <strong>Last Modified</strong>
            {{ relativeTime }}
            <span v-if="variant.last_modified_user">
              by {{ variant.last_modified_user.name }}</span
            >
          </p>
          <div
            v-if="variant.tags.length"
            class="flex flex-row flex-wrap gap-2 pt-2"
          >
            <span
              v-for="tag in variant.tags"
              :key="tag.id"
              class="text-xs w-fit px-2 py-0.5 bg-gray-500 rounded-full text-slate-800 font-bold"
              >{{ tag.name }}</span
            >
          </div>
        </div>
        <div class="flex flex-row gap-3 mt-1 mr-1">
          <router-link
            :to="{
              name: 'variant-edit',
              params: {
                clientId: snippet.client_id,
                snippetId: snippet.id,
                variantId: variant.id,
              },
            }"
            class="p-2 w-8 h-8 bg-slate-600 text-paper rounded-sm hover:bg-slate-900 duration-300 transition-colors"
          >
            <PencilIcon class="mx-auto" />
          </router-link>
          <button
            class="p-2 w-8 h-8 text-paper rounded-sm hover:bg-slate-900 duration-300 transition-colors"
            :class="
              snippet.primary_variant_id == variant.id
                ? 'bg-slate-900'
                : 'bg-slate-600'
            "
            @click="setPrimary"
          >
            <StarOpenIcon
              v-if="snippet.primary_variant_id != variant.id"
              class="mx-auto"
            />
            <StarIcon
              v-if="snippet.primary_variant_id == variant.id"
              class="mx-auto"
            />
          </button>
          <button
            class="p-2 w-8 h-8 text-paper rounded-sm bg-slate-600 hover:bg-slate-900 duration-300 transition-colors"
            @click="download"
          >
            <DownloadIcon class="mx-auto" />
          </button>
          <button
            class="p-2 w-8 h-8 bg-red-800 text-paper rounded-sm hover:bg-red-900 duration-300 transition-colors"
            @click="emitDelete(variant.id)"
          >
            <TrashIcon class="mx-auto" />
          </button>
        </div>
      </div>
      <div class="grid grid-cols-1 gap-4 py-4">
        <div class="px-4 w-full">
          <h4 class="text-xl text-slate-300 font-bold">Preview</h4>
          <HtmlPreview
            :htmlSnippet="variant.content"
            :button="true"
          ></HtmlPreview>
        </div>
        <div class="px-4 w-full">
          <h4 class="text-xl text-slate-300 font-bold">HTML Snippet</h4>
          <CodeEditor
            :value="variant.content"
            theme="atom-one-dark"
            font-size="0.875rem"
            width="100%"
            :languages="[['html', 'HTML']]"
            :read-only="true"
          ></CodeEditor>
        </div>
        <div v-if="variant.assets.length" class="px-4 w-full">
          <h4 class="text-xl text-slate-300 font-bold">Assets</h4>
          <div class="flex flex-row flex-wrap">
            <img
              v-for="asset in variant.assets"
              :key="asset.id"
              class="max-h-[70px]"
              :src="asset.url"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
