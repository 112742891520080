<script setup>
import { defineProps, ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import SpinnerIcon from "../icons/SpinnerIcon.vue";

const props = defineProps({
  client: Object,
});

const route = useRoute();
const router = useRouter();
const store = useStore();

const form = ref({
  title: "",
  notes: "",
  client_id: route.params.clientId,
  tags: [],
});

const snippetId = route.params.snippetId;
const isEditing = ref(snippetId ? true : false);

const isSubmitting = ref(false);
const submissionError = ref("");

const snippet = computed(() => store.state.currentSnippet);
const tags = computed(() => store.state.tags);

const isDisabled = computed(() => {
  const title = form.value.title.trim();
  return title === "";
});

const toggleTag = (tag) => {
  const tagIndex = form.value.tags.findIndex((t) => t == tag.id);
  if (tagIndex == -1) {
    form.value.tags.push(tag.id);
  } else {
    form.value.tags.splice(tagIndex, 1);
  }
};

const handleSubmit = async () => {
  if (isSubmitting.value) {
    return;
  }

  isSubmitting.value = true;
  submissionError.value = "";

  try {
    let response;
    if (isEditing.value) {
      response = await store.dispatch("updateSnippet", {
        id: snippetId,
        value: form.value,
      });
    } else {
      response = await store.dispatch("createSnippet", form.value);
    }
    router.push({
      name: "snippet-detail",
      params: { clientId: props.client.id, snippetId: response.data.id },
    });
  } catch (error) {
    console.error("Error creating snippet (component): ", error);
    submissionError.value =
      error.response?.data?.message || "An unknown error has occurred.";
  } finally {
    isSubmitting.value = false;
  }
};

onMounted(async () => {
  await store.dispatch("fetchTags");

  if (isEditing.value) {
    await store.dispatch("fetchSnippet", snippetId);
    form.value = {
      title: snippet.value.title,
      notes: snippet.value.notes,
      tags: snippet.value.tags.map((tag) => tag.id),
    };
  }
});
</script>

<template>
  <form
    @submit.prevent="handleSubmit"
    class="px-4 pt-6 pb-6 bg-slate-800 max-w-2xl rounded-sm grid grid-cols-1 gap-4"
  >
    <div class="">
      <label for="title" class="block text-xs text-gray-300 mb-1.5"
        >Title</label
      >
      <input
        type="text"
        name="title"
        v-model="form.title"
        placeholder="General Header"
        autofocus
        class="w-full px-2 py-1 rounded-sm bg-slate-600 text-paper"
      />
    </div>
    <div class="">
      <label for="notes" class="block text-xs text-gray-300 mb-1.5"
        >Notes</label
      >
      <textarea
        maxlength="255"
        rows="3"
        name="notes"
        v-model="form.notes"
        placeholder="Specific notes about snippet."
        class="w-full px-2 py-1 rounded-sm bg-slate-600 text-paper resize-none"
      />
    </div>
    <div class="">
      <label for="notes" class="block text-xs text-gray-300 mb-1.5">Tags</label>
      <div class="flex flex-row flex-wrap gap-4">
        <button
          v-for="tag in tags"
          :key="tag.id"
          :class="form.tags.includes(tag.id) ? 'bg-slate-200' : 'bg-slate-400'"
          @click.prevent="toggleTag(tag)"
          class="rounded-sm px-1.5 py-0.5 text-sm hover:bg-slate-200 duration-300 transition-colors"
        >
          {{ tag.name }}
        </button>
      </div>
    </div>
    <div class="">
      <button
        :disabled="isDisabled || isSubmitting"
        class="bg-paper text-sm px-3 py-1 rounded-sm hover:bg-slate-600 hover:text-paper duration-300 transition-colors disabled:bg-gray-400 disabled:text-gray-600"
      >
        <span v-if="!isSubmitting">Submit</span>
        <SpinnerIcon v-if="isSubmitting" class="animate-spin" />
      </button>
    </div>
    <div v-if="submissionError" class="text-red-700 text-xs text-center">
      {{ submissionError }}
    </div>
  </form>
</template>
