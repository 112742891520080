<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import SpinnerIcon from "../icons/SpinnerIcon.vue";

const route = useRoute();
const router = useRouter();
const store = useStore();

const form = ref({
  name: "",
  acronym: "",
});

const clientId = route.params.clientId;
const isEditing = ref(clientId ? true : false);

const isSubmitting = ref(false);
const submissionError = ref("");

const client = computed(() => store.state.currentClient);
const isDisabled = computed(() => {
  const name = form.value.name.trim();
  const acronym = form.value.acronym.trim();
  return name === "" || acronym === "";
});

const handleSubmit = async () => {
  if (isSubmitting.value) {
    return;
  }

  isSubmitting.value = true;
  submissionError.value = "";

  try {
    let response;
    if (isEditing.value) {
      response = await store.dispatch("updateClient", {
        id: clientId,
        value: form.value,
      });
    } else {
      response = await store.dispatch("createClient", form.value);
    }
    router.push({
      name: "client-detail",
      params: { clientId: response.data.id },
    });
  } catch (error) {
    console.error("Error creating client (component): ", error);
    submissionError.value =
      error.response?.data?.message || "An unknown error has occurred.";
  } finally {
    isSubmitting.value = false;
  }
};

onMounted(async () => {
  if (isEditing.value) {
    await store.dispatch("fetchClient", clientId);
    const c = client.value;
    if (c) {
      form.value = {
        name: c.name,
        acronym: c.acronym,
      };
    }
  }
});
</script>

<template>
  <form
    @submit.prevent="handleSubmit"
    class="px-4 pt-6 pb-6 bg-slate-800 max-w-2xl rounded-sm grid grid-cols-1 md:grid-cols-2 gap-4"
  >
    <div class="">
      <label for="name" class="block text-xs text-gray-300 mb-1.5">Name</label>
      <input
        type="text"
        name="name"
        v-model="form.name"
        placeholder="Lowe's Consumer"
        autofocus
        class="w-full px-2 py-1 rounded-sm bg-slate-600 text-paper"
      />
    </div>
    <div class="">
      <label for="acronym" class="block text-xs text-gray-300 mb-1.5"
        >Acronym</label
      >
      <input
        type="text"
        name="acronym"
        maxlength="4"
        v-model="form.acronym"
        placeholder="SFLW"
        class="w-full px-2 py-1 rounded-sm bg-slate-600 text-paper uppercase"
      />
    </div>
    <div class="">
      <button
        :disabled="isDisabled || isSubmitting"
        class="bg-paper text-sm px-3 py-1 rounded-sm hover:bg-slate-600 hover:text-paper duration-300 transition-colors disabled:bg-gray-400 disabled:text-gray-600"
      >
        <span v-if="!isSubmitting">Submit</span>
        <SpinnerIcon v-if="isSubmitting" class="animate-spin" />
      </button>
    </div>
    <div
      v-if="submissionError"
      class="text-red-700 text-xs col-span-2 text-center"
    >
      {{ submissionError }}
    </div>
  </form>
</template>
