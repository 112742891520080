<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

import ClientForm from "@/components/forms/ClientForm.vue";

const store = useStore();

const client = computed(() => store.state.currentClient);
</script>

<template>
  <div>
    <h1 class="text-4xl font-bold mb-4">Edit {{ client.name }}</h1>
    <ClientForm />
  </div>
</template>
